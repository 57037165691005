<template>
  <div>
    <div class="">
      <!-- Клиент Info -->
      <div
        v-if="contract.client && $route.name == 'OrderCreate'"
        class="p-2"
      >
        <p>
          <b>FIO</b>:
          {{
            [
              contract.client.lastname,
              contract.client.firstname,
              contract.client.middlename,
            ].join(" ")
          }}
        </p>
        <p><b>JShShIR</b>: {{ contract.client.pin }}</p>
        <p><b>Telefon</b>: {{ contract.client.phone }}</p>
        <p><b>Adres</b>: {{ contract.client.address }}</p>
      </div>
      <!-- <h3>Информация о контракте</h3> -->
      <div class="card p-2">
        <div
          v-if="
            contract.products &&
              contract.products.length &&
              contract.products[0].contract_id
          "
          class="mb-2"
        >
          <table class="w-100 table table-hover table-bordered table-responsive">
            <thead>
              <tr>
                <th>Nomi</th>
                <th>Soni</th>
                <th>Narxi</th>
                <th>Jami</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, index) in contract.products">
                <tr :key="index">
                  <td class="w-100">
                    <span
                      class="link font-weight-bolder"
                      @click="$emit('boughtProductInfo', product)"
                    >
                      {{
                        product.category ? product.category.name_uz : ""
                      }}</span>
                    <div
                      v-if="product.imei_1"
                      class="d-block mt-1"
                    >
                      <p>
                        IMEI:
                        <b
                          class="mt-2 text-primary"
                          @click="openImeiUpdateModal(product)"
                        > {{ product.imei_1 }}</b>
                      </p>
                      <p v-if="product.imei_2">
                        IMEI 2:
                        <b
                          class="mt-2 text-primary"
                          @click="openImeiUpdateModal(product)"
                        > {{ product.imei_2 }}</b>
                      </p>
                    </div>
                    <div
                      v-if="!product.imei_1"
                      class="d-block"
                    >
                      <b
                        class="mt-2 text-primary"
                        @click="openImeiUpdateModal(product)"
                      >
                        IMEI biriktirish
                      </b>
                    </div>
                    <div
                      v-if="product.i_cloud"
                      class="d-block"
                    >
                      <p>
                        iCloud:
                        <b
                          class="mt-2 text-primary"
                          @click="
                            $router.push({
                              name: 'iCloud-show',
                              params: { id: product.i_cloud.id },
                            })
                          "
                        >
                          {{ product.i_cloud.mail }}
                        </b>
                      </p>
                    </div>
                    <div
                      v-if="product.knox"
                      class="d-block"
                    >
                      <p>

                        <b
                          class="mt-2 text-primary"
                          @click="
                            $router.push({
                              name: 'knox-show',
                              params: { id: product.knox.id },
                            })
                          "
                        >
                          Knox ID: {{ product.knox.id }}
                        </b>
                      </p>
                    </div>
                    <div
                      v-else-if="!(product.knox || product.i_cloud)"
                      class="d-block"
                    >
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        class="btn-tour-skip mt-1"
                        @click="openICloudModal(product)"
                      >
                        <feather-icon
                          icon="LinkIcon"
                          size="12"
                          class="mr-1"
                        />
                        <span class="align-middle">Tizimga biriktirish</span>
                      </b-button>
                      <i class="d-block ">Knox va iCloud uchun</i>
                    </div>
                    <!-- UziIMEI -->
                    <div
                      v-if="product.imei_devices && product.imei_devices.length"
                      class="d-block"
                    >
                      <div
                        v-for="item in product.imei_devices"
                        :key="item.id"
                        class="primary-block"
                      >
                        <p><b>UzIMEI'ga ulangan: </b><span
                          class="link"
                          @click="goTo('uzimei-show', { id: item.id })"
                        >Ko'rish</span></p>
                        <p>
                          <b>Uzimei Status:</b>
                          <span
                            v-if="item.imei_1"
                            class="badge badge-primary"
                            :class="{ 'badge-danger': item.status_1 == 'blocked' }"
                          >
                            1: {{ item.status_1 }}
                          </span>
                          <b-button
                            v-else-if="product.imei_1 && !isHasAnother(1, product.imei_devices)"
                            v-can="'imei_devices.block'"
                            variant="outline-info"
                            class="mt-2 text-primary"
                            @click="connectUzimei(product)"
                          >
                            1-IMEI'ni biriktirish
                          </b-button>
                          <br>
                          <span
                            v-if="item.imei_2"
                            class="badge badge-primary"
                            :class="{ 'badge-danger': item.status_2 == 'blocked' }"
                          >
                            2: {{ item.status_2 }}
                          </span>
                          <b-button
                            v-else-if="product.imei_2 && !isHasAnother(2, product.imei_devices)"
                            v-can="'imei_devices.block'"
                            variant="outline-info"
                            class="mt-2 text-primary"
                            @click="connectUzimei(product)"
                          >
                            2-IMEI'ni biriktirish
                          </b-button>
                        </p>
                      </div>
                    </div>
                    <div
                      v-else
                      class="d-block"
                    >
                      <b-overlay :show="loadingUzimeiBtn">
                        <b-button
                          v-can="'imei_devices.block'"
                          variant="outline-info"
                          class="mt-2 text-primary"
                          @click="connectUzimei(product)"
                        >
                          UzIMEI'ga ulash
                        </b-button>
                        <i class="d-block">UzIMEI - masofadan aloqani uzish uchun</i>
                      </b-overlay>
                    </div>

                    <!-- 7Tech -->
                    <div
                      v-if="product.seven_tech_device"
                      class="d-block"
                    >
                      <div class="warning-block">
                        <p><b>7Tech'ga ulangan: </b><span
                          class="link"
                          @click="goTo('sevenTech-show', { id: product.seven_tech_device.id })"
                        >Ko'rish</span>
                        </p>
                        <p v-if="product.seven_tech_device.status == 'reserved'"><b>Qurilma biriktirilmagan: </b>
                          <!-- <span
                          class="link"
                          @click="attach7Tech(product)"
                        >Biriktirish</span> -->
                          <b-button
                            variant="outline-info"
                            class=" text-primary"
                            @click="attach7Tech(product)"
                          >
                            7Tech'ga biriktirish
                          </b-button>
                        </p>
                        <p>
                          <b>Status: </b>
                          <span
                            class="badge badge-primary"
                            :class="{ 'badge-danger': (product.seven_tech_device.status == 'blocked' || product.seven_tech_device.status == 'reserved') }"
                          >
                            {{ product.seven_tech_device.status }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      v-else
                      v-can="'seven_tech_devices.store'"
                      class="d-block"
                    >
                      <b-overlay :show="loadingUzimeiBtn">
                        <b-button
                          variant="outline-info"
                          class="mt-2 text-primary"
                          @click="connect7Tech(product)"
                        >
                          7Tech'ga ulash
                        </b-button>
                        <i class="d-block">7Tech - masofadan qurilmani bloklash(android uchun)</i>
                      </b-overlay>
                    </div>
                  </td>
                  <td>{{ product.product_quantity }} dona</td>
                  <!-- <td>dona</td> -->
                  <td style="white-space: nowrap">
                    {{ product.product_price | formatPrice }}so'm
                  </td>
                  <td style="white-space: nowrap">
                    {{ product.product_price_with_percent | formatPrice }}so'm
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <table class="table table-bordered table-responsive">
          <thead>
            <tr>
              <th
                colspan="2"
                class="text-center"
              >
                Детали контракта
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Срок рассрочки (месяц)</th>
              <td>{{ contract.credit_months }}</td>
            </tr>
            <tr>
              <th>Cумма предоплаты</th>
              <td>
                {{ contract.in_advance_payment_amount | formatPrice }} so'm
              </td>
            </tr>
            <tr v-if="contract.discount_amount">
              <th>Cумма скидка</th>
              <td>{{ contract.discount_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>Сумма рассрочки</th>
              <td>{{ contract.credit_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>День начала</th>
              <td>{{ dateDotFormat(contract.start_date) }}</td>
            </tr>
            <tr>
              <th>День окончания</th>
              <td>{{ dateDotFormat(contract.end_date) }}</td>
            </tr>
            <tr>
              <th>Контакты близких</th>
              <td>
                <span v-if="contract.near_phone1">
                  +998{{ contract.near_phone1 }}
                </span>
                <span v-if="contract.near_phone2">
                  , +998{{ contract.near_phone2 }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Кто зарегистрирован</th>
              <td v-if="contract.created_user">
                {{ contract.created_user.name }}
              </td>
            </tr>
            <template v-if="contract.is_rejected == 1">
              <tr>
                <th>Кто отклонил</th>
                <td>
                  {{
                    contract.rejected_user ? contract.rejected_user.name : ""
                  }}
                </td>
              </tr>
              <tr>
                <th>Причина отклонения</th>
                <td>
                  {{ contract.rejection_reason }}
                </td>
              </tr>
            </template>
            <tr v-can="'contracts.reject'">
              <td
                colspan="2"
                class="text-center"
              >
                <b-button
                  v-if="contract.status != 2 && contract.is_rejected == 0"
                  variant="outline-danger"
                  class="mr-1"
                  @click="reject_modal = true"
                >
                  Shartnomani bekor qilish
                </b-button>
                <b-button
                  v-else-if="contract.is_rejected == 1"
                  variant="danger"
                  class="mr-1"
                  disabled
                >
                  Shartnoma bekor qilingan
                </b-button>
                <b-button
                  v-if="contract.status == 2"
                  variant="warning"
                  disabled
                  class="mr-1"
                >
                  Shartnoma yopilgan
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="reject_modal">
      <b-modal
        v-model="reject_modal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        title="Shartnomani bekor qilish"
        @close="reject_modal = false"
        @cancel="reject_modal = false"
      >
        <RejectReason @submit="reject" />
      </b-modal>
    </div>
    <div v-if="imeiModal">
      <UpdateIMEIProduct
        :contract-product-id="attachForm.contract_product_id"
        :product-info="attachForm"
        @close="closeIMEIUpdateModal"
      />
    </div>
    <div v-if="icloudModal">
      <icloud-attach-modal
        :contract-product-id="attachForm.contract_product_id"
        :product-info="attachForm"
        @close="closeCloudModal"
      />
    </div>
    <div v-if="uzimeiModal">
      <uzimei-choose-modal
        :contract-product-id="attachForm.contract_product_id"
        :product-info="attachForm"
        @close="closeUzimeiModal"
      />
    </div>
    <!-- 7tech -->
    <div v-if="sevenTechModal">
      <b-modal
        v-model="sevenTechModal"
        size="lg"
        centered
        hide-footer
        no-close-on-backdrop
        title="Seven Tech'ni qurilmaga ulash"
        @close="close7TechModal"
        @cancel="close7TechModal"
      >
        <div v-if="sevenTechInfo">
          <img
            height="362"
            width="362"
            :src="sevenTechInfo.reserve_data.qr_code"
            alt="QR surati"
          >
          <span
            class="upload-label"
            style="margin: 0 auto 1rem"
          > Ushbu QR'ni yangi qurilma orqali skanerlang, muvaffaqiyatli
            o'rnatilgach oynani yoping. </span>
        </div>
        <div>
          <span>QR korinmayotgan bo'lsa </span>
          <span
            v-b-toggle.collapse-1
            class="link"
          >
            Bu yerga bosing
          </span>
          <b-collapse
            id="collapse-1"
            class="mt-2"
          >
            <b-card>
              <p class="card-text">
                Erkanda QR surati ko'rinmagan taqdirdagina yangi yarating
              </p>
              <b-button
                variant="outline-info"
                class="mt-2 text-primary"
                @click="connect7Tech(sevenTechInfo)"
              >
                7Tech'ga qayta ulash
              </b-button>
            </b-card>
          </b-collapse>

        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RejectReason from '@/components/modals/rejectReason.vue'
import icloudAttachModal from '@/components/modals/icloudAttach.vue'
import uzimeiChooseModal from '@/components/modals/uzimeiChoose.vue'
import UpdateIMEIProduct from '@/components/modals/UpdateIMEIProduct.vue'

import { showToast } from '@/utils/toast'

export default {
  components: {
    RejectReason,
    icloudAttachModal,
    UpdateIMEIProduct,
    uzimeiChooseModal,
  },
  props: {
    orderId: {
      type: [Number, String],
      default: () => null,
    },
    contract: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      reject_modal: false,
      icloudModal: false,
      loadingUzimeiBtn: false,
      uzimeiModal: false,
      iCloud_objs: null,
      iCloudsList: [],
      imeiModal: false,
      sevenTechModal: false,
      sevenTechInfo: null,
      attachForm: {
        contract_product_id: null,
        icloud_id: null,
        imei: null,
        imei_1: null,
        imei_2: null,
      },
    }
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({
      rejectContract: 'contract/rejectContract',
      storeUzimei: 'imei/store',
      store7Tech: 'sevenTech/store',
    }),
    isIphone(product) {
      // const isHas = product.category && (product.category.name_uz.includes('iphone') || product.category.name_uz.includes('iPhone') || product.category.name_uz.includes('pro max') || product.category.name_uz.includes('11') || product.category.name_uz.includes('12') || product.category.name_uz.includes('13') || product.category.name_uz.includes('14') || product.category.name_uz.includes('15'))
      return true
    },
    isHasAnother(which, devices) {
      return !!devices.find(e => e[`imei_${which}`])
    },
    closeCloudModal() {
      this.icloudModal = false
      this.$emit('getAction')
    },
    closeUzimeiModal() {
      this.uzimeiModal = false
      this.$emit('getAction')
    },
    closeIMEIUpdateModal() {
      this.imeiModal = false
      this.$emit('getAction')
    },
    openICloudModal(product) {
      this.attachForm.contract_product_id = product.id
      this.attachForm.imei = product.imei_1
      this.attachForm.imei_1 = product.imei_1
      this.attachForm.imei_2 = product.imei_2
      this.icloudModal = true
    },
    connectUzimei(product) {
      this.attachForm.contract_product_id = product.id
      this.attachForm.imei = product.imei_1
      this.attachForm.imei_1 = product.imei_1
      this.attachForm.imei_2 = product.imei_2
      if (!product.imei_1) {
        showToast('danger', this.$t('Ushbu mahsulotda IMEI biriktirilmagan'))
      } else {
        this.uzimeiModal = true
        // this.loadingUzimeiBtn = true
        // this.storeUzimei(this.attachForm).then(res => {
        //   if (res.success) {
        //     showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
        //     this.$emit('getAction', res.data)
        //   }
        // }).finally(() => {
        //   this.loadingUzimeiBtn = false
        // })
      }
    },
    attach7Tech(product) {
      this.sevenTechInfo = product.seven_tech_device
      this.sevenTechInfo.id = product.id
      this.sevenTechModal = true
    },
    connect7Tech(product) {
      // if (!product.imei_1) {
      //   showToast('danger', this.$t('Ushbu mahsulotda IMEI biriktirilmagan'))
      // } else {
      this.$bvModal.msgBoxConfirm(this.$t('Qurilmani 7Techga ulamoqchimisiz'), {
        title: this.$t('Tasdiqlash'),
        size: 'sm',
        variant: 'warning',
        okVariant: 'primary',
        okTitle: this.$t('Ha'),
        cancelTitle: this.$t('Yo`q'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.loadingUzimeiBtn = true
            this.store7Tech({ contract_product_id: product.id }).then(res => {
              if (res.success) {
                showToast('success', this.$t('Muvaffaqiyatli yaratildi'))
                this.sevenTechModal = true
                this.sevenTechInfo = res.data
              }
            }).finally(() => {
              this.loadingUzimeiBtn = false
            })
          }
        })
      // }
    },
    close7TechModal() {
      this.sevenTechModal = false
      this.$emit('getAction', true)
    },
    openImeiUpdateModal(product) {
      this.attachForm.contract_product_id = product.id
      this.attachForm.imei_1 = product.imei_1
      this.attachForm.imei_2 = product.imei_2
      this.imeiModal = true
    },
    reject(reason) {
      const data = {
        contract_id: this.$route.params.id,
        rejection_reason: reason,
      }
      this.rejectContract(data).then(res => {
        if (res.success) {
          showToast('success', this.$t('Успешно оформлен'))
          this.$router.push({ name: 'OrdersIndex' })
        }
      })
    },
  },
}
</script>

<style></style>
