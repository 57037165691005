<template>
  <div>
    <a
      class="link"
      @click="back"
    >
      Назад
    </a>
    <b-overlay :show="loading">
      <div class="row">
        <div class="col-12 col-lg-5">
          <div
            v-if="contract.online_contract"
            class="text-center mt-1"
          >
            <div class="d-flex">
              <span class="badge badge-primary w-100">
                Ushbu shartnoma onlayn tuzilgan
              </span>
              <!-- <b-button
                v-if="contract.online_contract.status==1"
                variant="outline-primary"
                class="ml-1"
                @click="scrollToDiv('myid')"
              >Batafsil</b-button> -->
            </div>
            <div v-if="contract.online_contract.status==0">
              <span class="badge badge-danger w-100 mt-1">
                Sharnoma imzolanmagan
              </span>
              <p class="bg-white"><b>Imzolash uchun havola: </b>
                {{ contract.online_contract.link }}
                <b-button
                  variant="outline-primary"
                  @click="copyClipboard(contract.online_contract.link)"
                >
                  <feather-icon
                    icon="LinkIcon"
                    size="12"
                    class="mr-1"
                  />
                  Nusxalash
                </b-button>
              </p>
            </div>
          </div>
          <div
            v-if="contract.is_dollar"
            class="text-center mt-1 mb-1"
          >
            <span class="badge badge-success w-100">
              Ushbu shartnoma valyutada tuzilgan
            </span>
          </div>
          <div
            v-if="contract.is_rejected == 1"
            class="text-center"
          >
            <b-badge
              pill
              class="fs18 p-1 my-2"
              variant="light-danger"
            >
              Shartnoma bekor qilingan
            </b-badge>
          </div>
          <div v-else-if="all_credit_debt != 0">
            <template v-if="isUploadFiles">
              <div v-if="canCreate">
                <h3>Оформления платежа</h3>
                <span
                  v-if="isWaiting"
                  class="text-warning fs18"
                >Fayllar tekshirilmoqda. Iltimos kuting</span>
                <span
                  v-else-if="isRejected"
                  class="text-danger fs18"
                >Fayllar rad etildi. Iltimos tuzatib qayta yuklang</span>
                <div
                  class="card p-2"
                  :class="isWaiting || isRejected ? 'double-overlay' : ''"
                >
                  <validation-observer
                    ref="form"
                    #default="{ invalid }"
                  >
                    <b-form
                      class="auth-login-form mt-2"
                      @submit.prevent="submit"
                    >
                      <b-form-group
                        :label="$t('Сумма')"
                        label-for="amount"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Сумма')"
                          rules="required"
                        >
                          <cleave
                            id="amount"
                            v-model="form.amount"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :options="cleaveOption"
                            placeholder="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <span
                            v-if="contract.is_dollar"
                            class="mt-1"
                          >{{ (form.amount / dollarRate).toFixed(2) }} $</span>
                        </validation-provider>
                      </b-form-group>
                      <div class="row">
                        <div class="col-6">
                          <b-form-group
                            :label="$t('Сальдо на начало')"
                            label-for="name"
                          >
                            <cleave
                              id="name"
                              :value="all_credit_debt"
                              disabled
                              class="form-control"
                              :options="cleaveOption"
                              placeholder="0"
                            />
                            <span
                              v-if="contract.is_dollar"
                              class="mt-1"
                            >
                              Oylik to'lov
                              <b>{{
                                credit_debt_monthly
                                  ? credit_debt_monthly.toFixed(2)
                                  : 0 | formatPrice
                              }}</b>
                              so'm</span>
                          </b-form-group>
                        </div>
                        <div class="col-6">
                          <b-form-group
                            :label="$t('Сальдо на конец')"
                            label-for="name"
                          >
                            <cleave
                              id="name"
                              v-model="form.credit_balance"
                              class="form-control"
                              disabled
                              :options="cleaveOption"
                              placeholder="0"
                            />
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <b-form-textarea
                            id="comment"
                            v-model="form.comment"
                            class="form-control"
                            placeholder="Комментарий"
                            rows="3"
                          />
                        </div>
                        <div class="col-4">
                          <div class="mt-2 text-right">
                            <b-button
                              :disabled="!form.amount"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                            >
                              Оформить
                            </b-button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="mt-2 text-right">
                        <b-button
                          :disabled="!form.amount"
                          type="submit"
                          variant="primary"
                          class="mr-1"
                        >
                          Оформить
                        </b-button>
                      </div> -->
                    </b-form>
                  </validation-observer>
                </div>
              </div>
            </template>
            <template v-else>
              <h5 class="text-danger">
                {{
                  $t(
                    "Siz shartnoma nusxasi yoki passport nusxasini yuklamagansiz. To'lovdan oldin ularni yuklash talab etiladi."
                  )
                }}
              </h5>
              <div
                v-can="'contracts.store'"
                class="card p-2"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="visible = true"
                >
                  {{ $t("Загрузить фото паспорта и контракта") }}
                </b-button>
              </div>
            </template>
          </div>

          <!-- Клиент Info -->
          <h3>Информации клиента</h3>
          <div class="card p-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Клиент
                  </th>
                </tr>
              </thead>
              <tbody v-if="contract && contract.client">
                <tr>
                  <th>ФИО</th>
                  <td
                    class="text-primary"
                    @click="
                      goTo('client-show', {
                        id: contract.client.id,
                      })
                    "
                  >
                    {{
                      [
                        contract.client.lastname,
                        contract.client.firstname,
                        contract.client.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ</th>
                  <td>{{ contract.client.pin }}</td>
                </tr>
                <tr>
                  <th>День рождения</th>
                  <td>{{ dateDotFormat(contract.client.date_birth) }}</td>
                </tr>
                <tr>
                  <th>Паспорт</th>
                  <td>
                    {{
                      contract.client.doc_series + contract.client.doc_number
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.client.phone }}</td>
                </tr>
                <tr>
                  <th>Скоринг информации</th>
                  <th>
                    <span
                      class="link"
                      @click="openClientScoring(contract.client_report_id)"
                    >
                      {{
                        [
                          contract.client.lastname,
                          contract.client.firstname,
                          contract.client.middlename,
                        ].join(" ")
                      }}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
            <table
              v-if="contract && contract.client2"
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Созаемщик
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ФИО</th>
                  <td>
                    {{
                      [
                        contract.client2.lastname,
                        contract.client2.firstname,
                        contract.client2.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ</th>
                  <td>{{ contract.client2.pin }}</td>
                </tr>
                <tr>
                  <th>День рождения</th>
                  <td>{{ dateDotFormat(contract.client2.date_birth) }}</td>
                </tr>
                <tr>
                  <th>Паспорт</th>
                  <td>
                    {{
                      contract.client2.doc_series + contract.client2.doc_number
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.client2.phone }}</td>
                </tr>
                <tr>
                  <th>Скоринг информации</th>
                  <th>
                    <span
                      class="link"
                      @click="openClientScoring(contract.client2_report_id)"
                    >
                      {{
                        [
                          contract.client2.lastname,
                          contract.client2.firstname,
                          contract.client2.middlename,
                        ].join(" ")
                      }}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Trade In -->
          <h3>B/U telefon qabul qilish</h3>
          <div class="card p-2">
            <template v-if="parties && parties.data && parties.data.length">
              <table class="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th>Partya</th>
                    <th>Sana</th>
                    <th>Izoh</th>
                    <th>Summa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="party in parties.data"
                    :key="party.id"
                  >
                    <td
                      class="text-primary"
                      @click="
                        goTo('warehouseParty-show', {
                          id: party.id,
                        })
                      "
                    >
                      {{ party.id }}
                    </td>
                    <td>{{ formatDateYmd(party.date) }}</td>
                    <td>{{ party.comment }}</td>
                    <td>{{ party.cost |formatPrice }}</td>
                  </tr>

                </tbody>
              </table>
            </template>
            <div
              v-else
              class="text-right"
            >
              <p>Mijozdan olib qolingan telefonni kiriting</p>
              <b-button
                variant="outline-success"
                class="btn-tour-skip"
                @click="show_party_create = true"
              >
                <span class="mr-25 align-middle">
                  <feather-icon icon="PlusIcon" />
                  {{ $t("Приход товара") }}</span>
              </b-button>
            </div>
          </div>
        </div>

        <!-- График погашения -->
        <div class="col-12 col-lg-7">
          <h3>График погашения</h3>

          <contract-schedule-table
            :contract="contract"
            @refresh="getAction"
          />
          <!--  -->
          <div>
            <h3>Комментарии</h3>
            <div class="card p-2">
              <Comments />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <!-- payment history -->
          <div
            v-if="payment_list && payment_list.length"
            class="card p-2 table-responsive"
          >
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="5"
                    class="text-center"
                  >
                    Статус оплаты
                  </th>
                </tr>
                <tr>
                  <th>Сумма</th>
                  <th>Дата оплаты</th>
                  <th>Статус</th>
                  <th>Комментарий</th>
                  <th>Квитанция</th>
                </tr>
              </thead>
              <tbody v-if="payment_list && payment_list.length">
                <tr
                  v-for="(payment, index) in payment_list"
                  :key="index"
                  :class="payment.type == 2 ? 'table-active' : ''"
                >
                  <th style="white-space: nowrap">
                    {{ payment.amount | formatPrice }} so'm
                  </th>
                  <td>{{ dateDotFormat(payment.created_at) }}</td>
                  <td class="d-flex flex-column  align-items-start">
                    <b-badge
                      v-if="payment.type == 2"
                      pill
                      variant="info"
                      class="mb-1 mr-1"
                    >
                      {{ $t("Первоначальный взнос") }}
                    </b-badge>

                    <b-badge
                      pill
                      :variant="
                        payment.status == 1
                          ? 'info'
                          : payment.status == 2
                            ? 'success'
                            : 'warning'
                      "
                    >
                      {{ getStatuses(payment) }}
                    </b-badge>
                    <span
                      v-if="payment.status == 1 && payment.confirmed_at_1"
                    >O'rinbosar tasdiqlagan sana:
                      <b>{{ dateDotFormat(payment.confirmed_at_1) }}</b></span>
                    <span
                      v-if="payment.status == 2 && payment.confirmed_at_2"
                    >Direktor tasdiqlagan sana:
                      <b>{{ dateDotFormat(payment.confirmed_at_2) }}</b></span>
                  </td>
                  <td>{{ payment.comment }}</td>
                  <td>
                    <span
                      class="link"
                      @click="printCheck(payment.id)"
                    >Распечатать</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Contract info -->
          <h3>Информация о контракте</h3>
          <template v-if="contract">
            <contract-show
              :contract="contract"
              :order-id="$route.params.id"
              @boughtProductInfo="boughtProductInfo"
              @getAction="getAction"
            />
          </template>
        </div>

        <div class="col-12 col-lg-6">
          <!-- Autopay Info -->
          <h3>Auto to'lov undirish ma'lumotlari</h3>
          <div class="card p-2">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    Uni Access mijoz
                  </th>
                </tr>
              </thead>
              <tbody v-if="contract && contract.uni_access_client">
                <tr>
                  <th>ФИО</th>
                  <td
                    class="text-primary"
                    @click="
                      goTo('AutopayClient-show', {
                        id: contract.uni_access_client.id,
                      })
                    "
                  >
                    {{
                      [
                        contract.uni_access_client.lastname,
                        contract.uni_access_client.firstname,
                        contract.uni_access_client.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ / Паспорт</th>
                  <td>
                    {{ contract.uni_access_client.pin }} /
                    {{ contract.uni_access_client.passport }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.uni_access_client.phones.join(" ") }}</td>
                </tr>
                <tr>
                  <th>Avtomatik pul yechish</th>
                  <td>
                    <b-overlay :show="loading_checkbox">
                      <b-form-checkbox
                        v-model="contract.uni_access_client.auto"
                        :value="1"
                        :unchecked-value="0"
                        switch
                        inline
                        @input="toggleChange"
                      />
                    </b-overlay>
                  </td>
                </tr>
                <tr>
                  <th>To'lanmagan</th>
                  <td class="text-danger font-weight-bold">
                    {{
                      contract.uni_access_client.remaining_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>To'langan</th>
                  <td class="text-success font-weight-bold">
                    {{
                      contract.uni_access_client.paid_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>Jami qarzdorlik</th>
                  <td class="text-warning font-weight-bold">
                    {{
                      contract.uni_access_client.debit_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td>Hozircha ma'lumot yo'q</td>
                </tr>
              </tbody>
            </table>
            <table
              v-if="contract && contract.uni_access_client2"
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-center"
                  >
                    UNI ACCESS MIJOZ 2 (KAFIL)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>ФИО</th>
                  <td
                    class="text-primary"
                    @click="
                      goTo('AutopayClient-show', {
                        id: contract.uni_access_client2.id,
                      })
                    "
                  >
                    {{
                      [
                        contract.uni_access_client2.lastname,
                        contract.uni_access_client2.firstname,
                        contract.uni_access_client2.middlename,
                      ].join(" ")
                    }}
                  </td>
                </tr>
                <tr>
                  <th>ПИНФЛ / Паспорт</th>
                  <td>
                    {{ contract.uni_access_client2.pin }} /
                    {{ contract.uni_access_client2.passport }}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{{ contract.uni_access_client2.phones.join(" ") }}</td>
                </tr>
                <tr>
                  <th>Avtomatik pul yechish</th>
                  <td>
                    <b-overlay :show="loading_checkbox">
                      <b-form-checkbox
                        v-model="contract.uni_access_client2.auto"
                        :value="1"
                        :unchecked-value="0"
                        switch
                        inline
                        @input="toggleChange"
                      />
                    </b-overlay>
                  </td>
                </tr>
                <tr>
                  <th>To'lanmagan</th>
                  <td class="text-danger font-weight-bold">
                    {{
                      contract.uni_access_client2.remaining_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>To'langan</th>
                  <td class="text-success font-weight-bold">
                    {{
                      contract.uni_access_client2.paid_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
                <tr>
                  <th>Jami qarzdorlik</th>
                  <td class="text-warning font-weight-bold">
                    {{
                      contract.uni_access_client2.debit_amount | formatPrice
                    }}
                    so'm
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <!-- Online contract info -->
          <div
            v-if="contract.online_contract"
            class="card p-2"
          >
            <h3 class="badge badge-primary w-100">
              Online shartnoma ma'lumotlari
            </h3>
            <h3 class=""><a
              :href="storageUrlG + 'contracts/' + contract.online_contract.file_name"
              class="font-weight-bold link"
              target="_blank"
            >Shartnoma PDF
              <feather-icon icon="PaperclipIcon" />
            </a></h3>

            <p><b>Mijoz imzosi uchun havola: </b><a
              :href="contract.online_contract.link"
              target="_blank"
              class="link"
            >Havola</a></p>
            <p><b>Imzo sanasi: </b><span>{{ formatDateYmd(contract.online_contract.signed_at) }}</span></p>
            <p><b>MyID surati: </b>
              <el-image
                v-if="contract.online_contract.photo"
                fit="cover"
                class="profile-avatar"
                :src="contract.online_contract.photo"
                :preview-src-list="[contract.online_contract.photo]"
              />
              <span v-else>
                Surat topilmadi</span>
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
    <!-- Contract passport Photo -->
    <div>
      <div v-if="!isUploadFiles">
        <b-modal
          v-model="visible"
          size="xl"
          centered
          hide-footer
          no-close-on-backdrop
          @close="visible = false"
          @cancel="visible = false"
        >
          <ContractUpload
            :is-contract-show="true"
            @close="closeUpload"
          />
        </b-modal>
      </div>
      <div v-else>
        <ContractUpload
          :is-contract-show="true"
          :is-show="true"
        />
      </div>
    </div>
    <!-- Scoring Modal -->
    <div v-if="clientReport">
      <b-modal
        v-model="openClientScoringModal"
        centered
        hide-footer
        no-close-on-backdrop
        @close="openClientScoringModal = false"
        @cancel="openClientScoringModal = false"
      >
        <h4 class="text-center">
          Mijoz scoring ma'lumotlari
        </h4>
        <response-show
          :form="form"
          type="clientReport"
          :client-report="clientReport"
        />
      </b-modal>
    </div>

    <!-- Bought Products Info  Modal -->
    <div v-if="openBoughtProductsModal">
      <b-modal
        v-model="openBoughtProductsModal"
        centered
        hide-footer
        size="xl"
        no-close-on-backdrop
        @close="openBoughtProductsModal = false"
        @cancel="openBoughtProductsModal = false"
      >
        <h4 class="text-center">
          Sotib olingan mahsulot
        </h4>
        <BoughtProductsInfo :bought-products-info="boughtProductsInfo" />
      </b-modal>
    </div>
    <!--  -->
    <div v-if="show_party_create">
      <b-modal
        v-model="show_party_create"
        size="xl"
        centered
        hide-footer
        no-close-on-backdrop
        @close="show_party_create = false"
        @cancel="show_party_create = false"
      >
        <div class="text-center">
          <PartyCreate
            :is-component="true"
            :contract-id="$route.params.id"
            :p-party-type="2"
            @save="getAction"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'

import ContractScheduleTable from '@/components/tables/contractSchedule.vue'
import { getItem } from '@/utils/storage'
import ResponseShow from '../order/components/response_show.vue'
import Comments from './comments.vue'
import ContractShow from './contractShow.vue'
import BoughtProductsInfo from './boughtProductsInfo.vue'
import ContractUpload from '../order/show.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
    Comments,
    BoughtProductsInfo,
    ContractShow,
    ContractUpload,
    ContractScheduleTable,
    ResponseShow,
    PartyCreate: () => import('@/views/warehouse/parties/save.vue'),
  },
  data() {
    return {
      form: {
        contract_id: null,
        comment: '',
        amount: null,
        credit_balance: null,
      },
      show_party_create: false,
      boughtProductsInfo: [],
      title: '',
      visible: true,
      loading_checkbox: false,
      openClientScoringModal: false,
      openBoughtProductsModal: false,
      clientReport: null,
      loading: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      payment_list: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract/GET_ITEM',
      parties: 'parties/GET_ITEMS',
    }),
    dollarRate() {
      const rate = getItem('realDollarRate')
      return Number(rate)
    },
    all_credit_debt() {
      if (this.contract.is_dollar) {
        return (
          (Number(this.contract.credit_amount_dollar)
            - Number(this.contract.all_payment_amount_dollar))
          * this.dollarRate
        )
      }
      return (
        Number(this.contract.credit_amount)
        - Number(this.contract.all_payment_amount)
        + Number(this.contract.penalty_amount)
      )
    },
    credit_debt_monthly() {
      return Number(this.contract.schedule[0].amount_dollar) * this.dollarRate
    },
    isWaiting() {
      return (
        this.isUploadFiles && this.contract.images_confirmation_status == 0
      )
    },
    isRejected() {
      return this.contract.images_confirmation_status == 2
    },
    isConfirmed() {
      return this.contract.images_confirmation_status == 1
    },
    contractId() {
      return this.$route.params.id
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    storagePaymentsUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/payments/`
    },
    isUploadFiles() {
      if (this.contract && this.contract.client2) {
        return !!(
          this.contract.contract_pdf_url
          && this.contract.passport_url2
          && this.contract.passport_address_url2
          && this.contract.passport_url
          && this.contract.passport_address_url
        )
      }
      return !!(
        this.contract.contract_pdf_url
        && this.contract.passport_url
        && this.contract.passport_address_url
      )
    },
    canCreate() {
      return this.hasPermission('payments.store')
    },
    canUpload() {
      return (
        this.hasPermission('contracts.update') && this.contract.is_rejected == 0
      )
    },
  },
  watch: {
    'form.amount': function (val) {
      this.form.credit_balance = this.all_credit_debt - val
      if (Number(val) > Number(this.all_credit_debt) + 100) {
        this.form.amount = this.all_credit_debt
      }
    },
    $route() {
      this.form.contract_id = this.$route.params.id
      this.getAction()
    },
  },
  mounted() {
    this.form.contract_id = this.$route.params.id
    this.getAction()
    this.getPariesItems()
  },
  methods: {
    ...mapActions({
      getPartiesList: 'parties/index',
      getItemAction: 'contract/show',
      makePaymentPdf: 'resource/makePaymentPdf',
      getClientReportInfo: 'resource/getClientReportInfo',
      getClientReport: 'resource/getClientReport',
      paymentStore: 'contract/paymentStore',
      getContractProductInfo: 'contract/getContractProductInfo',
      paymentList: 'contract/paymentList',
      autopayToggle: 'resource/autopayToggle',
    }),
    async getPariesItems() {
      this.loading = true
      await this.getPartiesList({ contract_id: this.$route.params.id })
      this.loading = false
    },
    toggleChange() {
      this.loading_checkbox = true
      this.autopayToggle({
        loan_id: this.contract.uni_access_client.loan_id,
        auto: this.contract.uni_access_client.auto,
      })
        .then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
        })
        .finally(() => {
          this.loading_checkbox = false
        })
    },
    openClientScoring(clientReportId) {
      this.clientReport = null
      this.getClientReport({ id: clientReportId })
        .then(res => {
          if (res) {
            this.clientReport = res.data
          }
        })
        .finally(() => {
          this.openClientScoringModal = true
        })
    },
    boughtProductInfo(product) {
      this.getContractProductInfo({
        contract_id: product.contract_id,
        product_category_id: product.product_category_id,
        relations: 'product.party.provider',
      })
        .then(res => {
          if (res) {
            this.boughtProductsInfo = res.data
          }
        })
        .finally(() => {
          this.openBoughtProductsModal = true
        })
    },
    async copyClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        showToast('success', 'Nusxa olindi', 'CheckCircleIcon')
      } catch ($e) {
        // alert('Cannot copy')
      }
    },
    scrollToDiv(ref) {
      // const el = this.$refs[ref]

      // if (el) {
      // // Use el.scrollIntoView() to instantly scroll to the element
      //   el.scrollIntoView({ behavior: 'smooth' })
      // }
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    getStatuses(payment) {
      switch (payment.status) {
        case 1:
          return "O'rinbosar tasdiqlagan"
        case 2:
          if (payment.uni_access_payment_id) {
            return "Avtoto'lov orqali yechilgan"
          }
          return 'Direktor tasdiqlagan'

        default:
          return 'Tasdiqlanmagan'
      }
    },
    async submit() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.paymentStore(this.form)
          .then(res => {
            if (res.success) {
              showToast('success', this.$t('Успешно оформлен'))
              this.form.amount = 0
              this.form.comment = ''
              this.getAction()
              // this.paymentList({ contract_id: this.$route.params.id })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    getAction() {
      this.show_party_create = false
      this.getItemAction({
        id: this.$route.params.id,
        appends: 'actual_dollar_rate',
        relations:
          'createdUser|client|client2|payments|rejectedUser|products.category|products.knox|documents|uniAccessClient|uniAccessClient2|onlineContract|products.imeiDevices',
      }).then(resp => {
        if (resp.success) {
          this.payment_list = resp.data.payments
        }
      })
    },
    printCheck(id) {
      this.makePaymentPdf(id).then(res => {
        this.contract_path = `${this.storagePaymentsUrl + res.data}.pdf`
        window.open(`${this.storagePaymentsUrl + res.data}.pdf`, '_blank')
        // this.downloadFile()
        // this.downloadAxiosFile()
      })
    },
    closeUpload() {
      this.visible = false
      this.getAction()
    },
  },
}
</script>

<style></style>
